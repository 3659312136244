
import { Vue, Component, Ref } from 'vue-property-decorator';
import { ValidationRules } from '../models/validationRules';
import { ForgotPasswordParams } from '@/models/users/forgotPasswordParams';
import { UserServiceClient } from '@/api/clients/userServiceClient';
import Notification from '@/components/notifications/notification.vue';
import { NotificationType } from '@/types/notificationType';

const userServiceClient = new UserServiceClient();
@Component({
  components: { Notification }
})
export default class ForgotPassword extends Vue {
  @Ref('notification') readonly notification!: Notification;
  public rules: ValidationRules = new ValidationRules();
  public forgotPasswordParams: ForgotPasswordParams = new ForgotPasswordParams();
  public success: boolean = false;

  private get notificationInstance(): NotificationType {
    return this.$refs.notification as NotificationType;
  }

  public async formSubmit(form: VForm) {
    this.notificationInstance.hide();

    try {
      if (form.validate()) {
        await userServiceClient.resetPassword({
          username: this.forgotPasswordParams.username
        });
        this.success = true;
      }
    } catch (err) {
      console.error(err.response);
      this.notificationInstance.showError(
        'There was an error trying to reset your password.'
      );
    }
  }
}
